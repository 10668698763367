@import './partials/normalize';
@import './partials/fonts';
@import './partials/buttons';
@import './partials/typography';
@import './partials/table';
@import './partials/inputs';

// colors. to access in a file use as such: var(--primary)
:root {
  // other
  --bg: #f2f2f2;
  // primary
  --primary: #183684;
  --primary-020: #cdd4e4;
  --primary-050: #8b9ac1;
  --primary-060: #7486b5;
  --primary-070: #5d72a9;
  --primary-080: #465e9d;
  --primary-090: #2f4a90;
  --primary-110: #163177;
  --primary-120: #132b6a;
  --primary-130: #11265d;
  --primary-140: #0e204f;
  --primary-150: #0c1b42;
  // secondary
  --secondary: #007ac8;
  --secondary-050: #7fbce3;
  --secondary-060: #66afde;
  --secondary-070: #4ca2d8;
  --secondary-080: #3395d3;
  --secondary-090: #1987cd;
  --secondary-110: #006eb4;
  --secondary-120: #0062a0;
  --secondary-130: #00568c;
  --secondary-140: #004978;
  --secondary-150: #003d64;
  // tertiary
  --tertiary: #96976f;
  --tertiary-050: #cacbb7;
  --tertiary-060: #c0c1a9;
  --tertiary-070: #b5b69a;
  --tertiary-080: #abac8c;
  --tertiary-090: #a0a17d;
  --tertiary-110: #878864;
  --tertiary-120: #787959;
  --tertiary-130: #696a4e;
  --tertiary-140: #5a5b43;
  --tertiary-150: #4b4c38;
  //greys
  --white: #ffffff;
  --grey-010: #e6e6e6;
  --grey-020: #dadada;
  --grey-030: #cecece;
  --grey-040: #c2c2c2;
  --grey-050: #b5b5b5;
  --grey-060: #aaaaaa;
  --grey-070: #9e9e9e;
  --grey-080: #919191;
  --grey-090: #858585;
  --grey-100: #797979;
  --grey-110: #6d6d6d;
  --grey-120: #616161;
  --grey-130: #545454;
  --grey-140: #494949;
  --grey-150: #3d3d3d;
  --grey-160: #303030;
  --grey-170: #242424;
  --grey-180: #191919;
  --grey-190: #0c0c0c;
  // status
  --error: #a21e1f;
  --error-rgb: 162, 30, 31; // Value is RGB triplet of --error
  --error-120: #85191a;
  --error-140: #671314;
  --warning: #c87900;
  --warning-120: #a34a00;
  --warning-080: #b28400;
  --ok: #4098ca;
  --success: #037314;
  // inputs
  --input-border: var(--grey-090);
  --input-hover: var(--grey-120);
  --input-active: var(--grey-150);
  --input-error: var(--error);
  --input-error-hover: var(--error-120);
  --input-error-active: var(--error-140);
  --input-border-disabled: #bab8b5;
  --input-button-disabled: #e1dfdd;
  --input-background-disabled: #edebe9;
  // tables
  --header-divider: var(--grey-060);
  --row-divider: var(--grey-030);
  --row-hover: var(--grey-020);
  --row-active: var(--grey-030);
  --tooltip-indicator: var(--grey-030);
  // text
  --text-primary: #313034;
  --text-secondary: #605e5c;
  --text-placeholder: #787673;
  --text-hyperlink: #0055b9;
  --text-hyperlink-hover: #00397f;
  --text-hyperlink-visited: #46007f;
  --text-error: var(--error);
  --text-disabled: var(--grey-090);
}

body,
html {
  background: var(--bg);
}

ul.breadcrumbs {
  padding: 0;
  list-style: none;
  margin-bottom: 3ch;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

ul.breadcrumbs li {
  display: inline;
  a {
    color: var(--text-secondary);
    text-decoration: none;
  }
}

ul.breadcrumbs li:last-of-type {
  font-weight: bold;
  a {
    color: var(--text-primary);
  }
}

ul.breadcrumbs li + li:before {
  padding: 1ch;
  content: '/';
}
