body,
input[type='text'] {
  font-family: 'Public Sans', 'Inter', sans-serif;
  color: var(--text-primary);
}

main h1 {
  margin-top: 0;
}

h1 {
  font-size: 36px;
  line-height: 54px;
  font-weight: 800;
}

h2 {
  font-size: 28px;
  line-height: 42px;
  font-weight: 800;
}

h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
}

h3 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 800;
}

p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

p.subhead,
p.semibold {
  font-weight: 600;
}

p.bold,
.bold,
b {
  font-weight: 800;
}

a {
  color: var(--text-hyperlink);
}

a:hover {
  color: var(--text-hyperlink-hover);
}

a:visited {
  color: var(--text-hyperlink-visited);
}

a.button:hover {
  color: var(--white);
}

dl.parent {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-row-gap: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 3ch;
}

dd,
dt {
  margin: 0;
  padding: 0;
}

dt {
  font-weight: 600;
  color: var(--text-secondary);
  padding-right: 1ch;
}

.secondary {
  color: var(--text-secondary);
}
