label {
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 0.5ch;
  color: var(--text-secondary);
}

input,
select,
.input {
  box-sizing: border-box;
  height: 36px;
  padding: 6px 8px;
  background: var(--white);
  border: 1px solid var(--input-border);
  border-radius: 3px;
  &:hover {
    border-color: var(--grey-120);
  }
  &:disabled {
    background: var(--input-background-disabled);
    color: var(--input-button-disabled);
  }
  &:active,
  &:focus {
    border: 1px solid var(--grey-150);
    outline: none;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
}

input[type='file'] {
  height: auto;
}

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder,
select:invalid {
  color: var(--text-placeholder);
  opacity: 1;
}

option:disabled,
option[disabled],
select:invalid,
input[type='date']:invalid::-webkit-datetime-edit {
  color: var(--text-placeholder);
  -webkit-text-fill-color: var(--text-placeholder);
}

input[type='search'] {
  background: var(--white) url('../../assets/images/iconSearch.svg') no-repeat
    center right;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  padding: none;
  margin: none;
  width: 24px;
  height: 24px;
  background: url('../../assets/images/iconCalendar.svg') no-repeat center right;
}
