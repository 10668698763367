th,
td {
  padding: 0.75rem;
}

table {
  border: none;
  font-size: 1em;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2ch;
}

td {
  border: none;
  border-bottom: 0.5px solid var(--row-divider);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

td.wide {
  word-wrap: break-word;
  max-width: 250px;
}

tbody tr:hover {
  background: var(--row-hover);
}

thead th {
  border: none;
  border-bottom: 1px solid var(--header-divider);
  text-align: left;
  vertical-align: top;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

tbody th,
td:first-of-type {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

td.numeric,
th.numeric {
  text-align: right;
}

caption {
  text-align: left;
  margin: 1em 0;
}
